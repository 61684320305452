.Navbar {
    display:none;
}

@media only screen and (max-width: 600px) {
    .Navbar {
        display:flex;
        flex-direction: column;
        width:100%;
        background-color:#6DAD7C;
        align-items:center;
        font-size: 18px;
        overflow-y:hidden;
        min-height:45px;
    }

    .Navbar .options {
        display:flex;
        height:0;
        width:100%;
        z-index: 2;
        position: absolute;
        top:45px;
        background-color:#7CBD8C;
        transition: height 0.5s ease-out;
        flex-shrink:0;
        flex-grow:0;
        overflow:hidden;
    }

    .Navbar .options .item {
        width:100%;
    }

    .Navbar .options.enabled {
        display:flex;
        height:75vh;
        justify-content: center;
        transition: height 0.5s ease-out;

    }

    .Navbar .toggleButton {
        display:flex;
        flex-direction: column;
        padding: 5px;
        justify-content: space-around;
        height: 35px;
        width: 45px;
        background-color:#7CCD9C;
        border-radius:5px;
        margin: 5px;
        margin-right:auto;
        flex-shrink:0;
    }
    
    .Navbar .line {
        width: 35px;
        height:2px;
        background-color: #574757;
    }
}