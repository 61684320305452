.Messages {
    display:flex;
    flex-direction: column;
    height:auto;
    flex-grow:1;
    justify-content: flex-start;
    padding: 10px;
    max-height:100%;
    flex-grow: 1;
    scrollbar-width:none;
    width:95%;
    scrollbar-width:none;
    overflow-y:scroll;
}

.Messages::-webkit-scrollbar {
    display: none;
}