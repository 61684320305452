.App {
  text-align: center;
  height:100vh;
  width:100vw;
  display:flex;
  flex-direction: column;
  background-color: #9CDC9C;
  max-height: 100vh;
  scrollbar-color: #8CCC9C #9CDC9C;
}

* {
  box-sizing: border-box;
}

.App .columns {
  width:100%;
  display:flex;
  flex-direction:row;
  max-height: 100vh;
  height:100%;

}

.App .columns .col {
  display:flex;
  height:100%;
  max-height:100%;
  flex-direction: column;
}

.App .columns .W-20 {
  width: 20rem;
}
.App .columns .W-80 {
  width: calc(100% - 20rem);
}

.App .btn, .App button {
  background-color: #7aba7a;
  border-style:none;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .App .columns .W-20 {
      width:0;
      overflow-x: hidden;
      z-index: 2;
  }
  .App .columns .W-80 {
    width:100%;
  }
}