.SettingsModal {
    width:20rem;
    height: 0;
    overflow:hidden;
    background-color: #4C9C5C;
    transition: height 1000ms;
    position:absolute;
    bottom:0;
    left:0;
    display:flex;
    flex-direction: column;
}

.SettingsModal.enabled {
    z-index: 10;
    height: 100%;
    width:20rem;
    transition: height 500ms;
}

.SettingsModal h1 {
    color:white;
}

.SettingsModal * {
    margin-left: 5px;
    margin-right: 5px;
}

.SettingsModal .close {
    margin-left: auto;
    padding: 5px;
}

.SettingsModal .contents {
    padding: 5px;
    display:flex;
    flex-direction: column;
}

.SettingsModal .contents .row {
    display:flex;
    flex-direction: row;
    width: 100%;
    margin:0;
}

@media only screen and (max-width: 600px) {
    .SettingsModal {
        width:100vw !important;
    }
}