.MessageBox {
    padding: 10px;
    width: auto;
    display:flex;
    height:fit-content;
}

.MessageBox textarea {
    background-color: #7CCC8C;
    border: none;
    border-radius: 5px;
    padding: 12px;
    transition: width 0.5s ease-in;
    height: 38px;
    width: 100%;
    resize: none;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */   
}

.MessageBox textarea::-webkit-scrollbar {
    display: none;
}

.MessageBox .submitBtn {
    display: none;
}

.MessageBox .empty {
    transition: width 0.5s ease-in;
    width: 25%;
    min-width: 200px;
}

.MessageBox textarea:focus {
    transition: width 0.5s ease-out;
    width:100%;
}

@media only screen and (max-width: 600px) {
    .MessageBox .empty {
        width:100%;
        margin-right: 4px;
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 0;
    }

    .MessageBox .submitBtn {
        display: flex;
        align-items: center;
        align-content: center;
        padding-right: 0.75rem;
        padding-left: 0.75rem;
    }
}