.SettingsButton {
    width:100%;
    display:flex;
    background-color: #71AF61;
    padding: 5px;
    align-items: center;
    justify-content: center;
}

.SettingsButton:hover {
    background-color: #71A161;
}